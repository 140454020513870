<template>
  <div class="flex-middle" :class="[classAdd]" :style="styleAdd" @click.prevent="$emit('click-back')">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    classAdd: {
      type: String,
      default: ''
    },
    styleAdd: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .flex-middle {
    display: flex;
    align-items: center;
    height: 100%;
    &.h-auto {
      height: auto;
    }
    &.w-full {
      width: 100%;
    }
    &.flex-wrap {
      flex-wrap: wrap;
    }
    &.flex-column {
      flex-direction: column;
    }
    &.flex-start {
      align-items: start;
    }
    &.flex-center {
      justify-content: center;
    }
    &.flex-end {
      justify-content: flex-end;
    }
    &.flex-space-beetween {
      justify-content: space-between;
    }
    &.align-stretch {
      align-items: stretch;
    }
    &.flex-top {
      align-items: flex-start;
    }
    &.flex-bottom {
      align-items: flex-end;
    }
    &.flex-reverse {
      flex-direction: row-reverse;
    }
    &.flex-grow {
      >div {
        flex-grow: 1;
      }
    }
    &.flex-flex {
      >div,
      >span {
        flex: 1;
      }
    }
  }
</style>
